import { FirebaseDataProvider, RAFirebaseOptions } from 'react-admin-firebase';
import firebaseProviderConfig from './firebaseProviderConfig.json';
import { DataProvider, RaRecord } from 'react-admin';

export default FirebaseDataProvider(
  firebaseProviderConfig.firebaseConfig,
  firebaseProviderConfig.firebaseOptions as RAFirebaseOptions,
);

export const SciDataProvider = (dataProvider: DataProvider) => ({
  ...dataProvider,
  getUserProfile() {
    const storedProfile = localStorage.getItem('profile');

    if (storedProfile) {
      return Promise.resolve({
        data: JSON.parse(storedProfile),
      });
    }

    // No profile yet, return a default one
    return Promise.resolve({
      id: 'unique-id',
      fullName: '',
      avatar: '',
    });
  },

  updateUserProfile(data: RaRecord) {
    return new Promise((resolve) => {
      localStorage.setItem(
        'profile',
        JSON.stringify({
          fullName: data.fullName,
          id: data.id,
          avatar: data.avatar,
        }),
      );
      resolve({ data });
    });
  },
});
