import * as React from 'react';
import { SVGProps } from 'react';
import { Box, SimplePaletteColorOptions } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    const primary = theme?.palette?.primary as SimplePaletteColorOptions;
    const secondary = theme?.palette?.secondary as SimplePaletteColorOptions;
    const logoSize = {
        x: 50,
        y: 38,
    };
    const themeTweaks: Record<string, any> =
        theme.palette.mode === 'dark'
            ? {
                  layer1Color: '#dddddd',
                  layer2Color: primary.light,
                  shadowOpacity: 0.3,
              }
            : {
                  layer1Color: primary.main,
                  layer2Color: secondary.main,
                  shadowOpacity: 0.3,
              };

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flex: '0 0 auto',
                alignSelf: 'stretch',
                margin: '0 1rem 0 .5rem',
                aspectRatio: `${logoSize.x} / ${logoSize.y}`,
                '& > svg': {
                    flex: '1 1 100%',
                    height: '100%',
                    width: 'auto',
                },
            }}
        >
            <img
                src="images/ppi-logo.png"
                height={logoSize.y}
                alt="PPI Secure Cloud Application Plattform"
            />
        </Box>
    );
};

export default Logo;
