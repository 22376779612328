import * as React from 'react';
import { DateField, DateInput, List, TextField, TextInput } from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import Welcome from '../dashboard/Welcome';

const StoreForm = () => (
    <RowForm>
        <TextField source="id" />
        <TextInput source="city" autoFocus />
        <TextInput source="country" />
        <TextInput source="address" />
        <DateInput source="created_at" />
    </RowForm>
);

export const StoreList = () => (
    <>
        <List hasCreate empty={false} perPage={25}>
            <EditableDatagrid
                data-testid="store-datagrid"
                rowClick="edit"
                createForm={<StoreForm />}
                editForm={<StoreForm />}
            >
                <TextField source="id" />
                <TextField source="city" />
                <TextField source="country" />
                <TextField source="address" />
                <DateField source="created_at" />
            </EditableDatagrid>
        </List>
        <Welcome />
    </>
);
