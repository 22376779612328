import * as React from 'react';
import { useEffect } from 'react';
import {
    combineDataProviders,
    CustomRoutes,
    defaultTheme,
    mergeTranslations,
    Resource,
} from 'react-admin';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
import { addEventsForMutations, EventList } from '@react-admin/ra-audit-log';
import CssBaseline from '@mui/material/CssBaseline';
import {
    raTreeLanguageEnglish,
    raTreeLanguageFrench,
} from '@react-admin/ra-tree';
import {
    raTourLanguageEnglish,
    raTourLanguageFrench,
} from '@react-admin/ra-tour';
import { Route } from 'react-router';
import { createTheme } from '@mui/material';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';
import frenchMessages from './i18n/fr';
import { getThemes } from './layout/themes';
import fakeServer from './fakeServer';

import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import reviews from './reviews';
import stores from './stores';
import dataProvider from './dataProvider';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';
import TourLauncher from './tours/TourLauncher';
import TourList from './tours/TourList';
import SciFirebaseDataProvider, {
    SciDataProvider,
} from './dataProvider/SciFirebaseDataProvider';

const messages = {
    en: mergeTranslations(
        englishMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    de: mergeTranslations(
        germanMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    fr: mergeTranslations(
        frenchMessages,
        raTreeLanguageFrench,
        raTourLanguageFrench
    ),
};

const i18nProvider = buildI18nProvider(messages, 'de');

const enhancedDataProvider = addEventsForMutations(dataProvider, authProvider);
const dataProvider2 = combineDataProviders(resource => {
    switch (resource) {
        case 'features':
            return SciFirebaseDataProvider;
        default:
            return enhancedDataProvider;
    }
});
const App = () => {
    useEffect(() => {
        const restoreFetch = fakeServer();
        return (): void => {
            restoreFetch();
        };
    }, []);
    const theme = createTheme(defaultTheme);
    const { darkTheme, lightTheme } = getThemes(theme);

    return (
        <Admin
            title=""
            dataProvider={dataProvider2}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
        >
            <CssBaseline />
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
                <Route path="/segments" element={<Segments />} />
                <Route key="tours" path="/tours" element={<TourList />} />
                <Route path="/tours/:tour" element={<TourLauncher />} />
            </CustomRoutes>
            <Resource name="customers" {...visitors} />
            <Resource name="commands" {...orders} />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} />
            <Resource name="reviews" {...reviews} />
            <Resource name="stores" {...stores} />
            <Resource name="locks" />
            <Resource name="events" list={EventList} />
        </Admin>
    );
};

export default App;
