import {
    mergeTranslations,
    TranslationMessages as BaseTranslationMessages,
} from 'react-admin';
import englishMessages from 'ra-language-english';

import { RaTreeTranslationMessages } from '@react-admin/ra-tree';
import { raAuditLogLanguageEnglish } from '@react-admin/ra-audit-log';

export interface TranslationMessages
    extends RaTreeTranslationMessages,
        BaseTranslationMessages {}

const customGermanMessages: TranslationMessages = mergeTranslations(
    englishMessages,
    raAuditLogLanguageEnglish,
    {
        ra: {
            action: {
                add_filter: 'Filter hinzufügen',
                add: 'Neu',
                back: 'Zurück',
                bulk_actions:
                    'Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt',
                cancel: 'Abbrechen',
                clear_input_value: 'Eingabe löschen',
                clone: 'Klonen',
                confirm: 'Bestätigen',
                create: 'Erstellen',
                delete: 'Löschen',
                edit: 'Bearbeiten',
                export: 'Exportieren',
                list: 'Liste',
                refresh: 'Aktualisieren',
                remove_filter: 'Filter entfernen',
                remove: 'Entfernen',
                save: 'Speichern',
                search: 'Suchen',
                show: 'Anzeigen',
                sort: 'Sortieren',
                undo: 'Zurücksetzen',
                unselect: 'Abwählen',
                expand: 'Erweitern',
                close: 'Schliessen',
                open_menu: 'Öffnen des Menüs',
                close_menu: 'Schliessen des Menüs',
            },
            boolean: {
                true: 'Ja',
                false: 'Nein',
                null: ' ',
            },
            page: {
                create: '%{name} erstellen',
                dashboard: 'Dashboard',
                edit: '%{name} #%{id}',
                error: 'Etwas ist schief gelaufen',
                list: '%{name}',
                loading: 'Die Seite wird geladen.',
                not_found: 'Nicht gefunden',
                show: '%{name} #%{id}',
                empty: 'Noch kein %{name}.',
                invite: 'Neu erstellen?',
            },
            input: {
                file: {
                    upload_several:
                        'Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.',
                    upload_single:
                        'Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.',
                },
                image: {
                    upload_several:
                        'Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.',
                    upload_single:
                        'Zum Hochladen Bild hineinziehen oder hier klicken, um ein Bild auszuwählen.',
                },
                references: {
                    all_missing:
                        'Die zugehörigen Referenzen konnten nicht gefunden werden.',
                    many_missing:
                        'Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.',
                    single_missing:
                        'Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.',
                },
                password: {
                    toggle_visible: 'Passwort ausblenden',
                    toggle_hidden: 'Passwort einblenden',
                },
            },
            message: {
                about: 'Über',
                are_you_sure: 'Sind Sie sicher?',
                bulk_delete_content:
                    'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
                bulk_delete_title:
                    'Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente',
                delete_content: 'Möchten Sie diesen Inhalt wirklich löschen?',
                delete_title: 'Lösche %{name} #%{id}',
                details: 'Details',
                error: 'Ein Fehler ist aufgetreten und Ihre Anfrage konnte nicht abgeschlossen werden.',
                invalid_form:
                    'Das Formular ist ungültig. Bitte überprüfen Sie Ihre Eingaben.',
                loading:
                    'Die Seite wird geladen, bitte haben Sie etwas Geduld.',
                no: 'Nein',
                not_found: 'Die Seite konnte nicht gefunden werden.',
                yes: 'Ja',
                unsaved_changes:
                    'Einige Änderungen wurden nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?',
            },
            navigation: {
                no_results: 'Keine Resultate gefunden',
                no_more_results: 'Die Seite %{page} enthält keine Inhalte.',
                page_out_of_boundaries:
                    'Die Seite %{page} liegt ausserhalb des gültigen Bereichs',
                page_out_from_end: 'Letzte Seite',
                page_out_from_begin: 'Erste Seite',
                page_range_info: '%{offsetBegin}-%{offsetEnd} von %{total}',
                page_rows_per_page: 'Zeilen pro Seite:',
                next: 'Weiter',
                prev: 'Zurück',
                skip_nav: 'Zum Inhalt springen',
            },
            auth: {
                auth_check_error: 'Bitte verbinden Sie sich um fortzufahren',
                user_menu: 'Profil',
                username: 'Nutzername',
                password: 'Passwort',
                sign_in: 'Anmelden',
                sign_in_error: 'Fehler bei der Anmeldung',
                logout: 'Abmelden',
            },
            notification: {
                updated:
                    'Element wurde aktualisiert |||| %{smart_count} Elemente wurden aktualisiert',
                created: 'Element wurde erstellt',
                deleted:
                    'Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht',
                bad_item: 'Fehlerhaftes Element',
                item_doesnt_exist: 'Das Element existiert nicht',
                http_error: 'Fehler beim Kommunizieren mit dem Server',
                data_provider_error:
                    'Fehler im dataProvider. Prüfe die Konsole für Details.',
                i18n_error:
                    'Die Übersetzungen für die angegebene Sprache können nicht geladen werden.',
                canceled: 'Aktion abgebrochen',
                logged_out:
                    'Ihre Sitzung wurde beendet, bitte verbinden Sie sich neu.',
            },
            validation: {
                required: 'Benötigt',
                minLength: 'Muss mindestens %{min} Zeichen lang sein',
                maxLength: 'Darf maximal %{max} Zeichen lang sein',
                minValue: 'Muss mindestens %{min} sein',
                maxValue: 'Muss %{max} oder weniger sein',
                number: 'Muss eine Nummer sein',
                email: 'Muss eine gültige E-Mail sein',
                oneOf: 'Es muss einer sein von: %{options}',
                regex: 'Es muss folgendem regulären Ausdruck entsprechen: %{pattern}',
            },
        },

        'ra-tree': {
            action: {
                add_root: 'Add a category of products',
            },
        },
        'ra-search': {
            result: `1 Ergebnis |||| %{smart_count} Ergebnisse`,
            recent: 'letzte Suchen',
        },
        pos: {
            search: 'Suche',
            configuration: 'Einstellungen',
            language: 'Sprache',
            theme: {
                name: 'Theme',
                light: 'Hell',
                dark: 'Dunkel',
            },
            filter: 'Filtered by',
            dashboard: {
                monthly_revenue: 'wichtiger Wert',
                month_history: 'Ein schönes Verlaufsdiagram',
                new_orders: 'Neue Daten',
                pending_reviews: 'Relevante Informationen',
                new_customers: 'Neue Personendaten',
                pending_orders: 'Datensätze mit einem bestimmten Status',
                all_customers: 'Alle Datensätze',
                all_reviews: 'Alle Hinweise',
                order: {
                    items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
                },
                timeline: 'Timeline',
                welcome: {
                    title: 'Willkommen in der Demo-Applikation des PPI Secure Cloud Application Stack',
                    subtitle:
                        'Diese Demo zeigt Ihnen einen Teil der Funktionalitäten, die wir unglaublich schnell in ihre individuellen Cloud-Anwendungen überführen können. Natürlich können wir hier nur einen Teil unseres Leistungsportfolios zeigen - gerne vereinbaren wir einen auf Ihre Bedürfnisse zugeschnittenen Showcase-Termin.',
                    ra_button: 'Zur offiziellen Landing-Page',
                    demo_button: 'Kontaktiert uns',
                    github_button: 'See the source code',
                },
            },
            menu: {
                sales: 'Verkäufe',
                catalog: 'Katalog',
                my_queries: 'My queries',
                customers: 'Kunden',
                new_customers: 'Neue Kunden',
                all_customers: 'Alle Kunden',
                visitors: 'Besucher',
                all_reviews: 'Alle Bewertungen',
                pending_reviews: 'ausstehende Bewertungen',
                bad_reviews: 'Schlechte Bewertungen',
            },
            reviews: {
                accepted: 'Akzeptiert',
                rejected: 'Zurückgewiesen',
                pending: 'Offen',
            },
        },
        resources: {
            customers: {
                name: 'Kunde |||| Kunden',
                fields: {
                    commands: 'Aufträge',
                    first_seen: 'Angemeldet',
                    groups: 'Segment',
                    last_seen: 'Zuletzt',
                    last_seen_gte: 'Visited Since',
                    name: 'Name',
                    total_spent: 'Total spent',
                    password: 'Passwort',
                    confirm_password: 'Passwort bestätigen',
                    address: 'Adresse',
                    birthday: 'Geburtstag',
                    city: 'Stadt',
                    first_name: 'Vorname',
                    has_newsletter: 'Newsletter',
                    has_ordered: 'Has ordered',
                    last_name: 'Nachname',
                    latest_purchase: 'Latest purchase',
                    zipcode: 'PLZ',
                    nb_commands: 'Aufträge',
                },
                filters: {
                    last_visited: 'zuletzt besucht',
                    today: 'heute',
                    this_week: 'diese Woche',
                    last_week: 'letzte Woche',
                    this_month: 'dieser Monat',
                    last_month: 'letzter Monat',
                    earlier: 'früher',
                    has_ordered: 'hat bestellt',
                    has_newsletter: 'Newsletter abonniert',
                    group: 'Segment',
                },
                fieldGroups: {
                    identity: 'Identity',
                    address: 'Address',
                    stats: 'Stats',
                    history: 'History',
                    password: 'Password',
                    change_password: 'Change Password',
                },
                page: {
                    delete: 'Delete Customer',
                },
                errors: {
                    password_mismatch:
                        'The password confirmation is not the same as the password.',
                },
            },
            commands: {
                name: 'Bestellung |||| Bestellungen',
                amount: 'Eine Bestellung |||| %{smart_count} Bestellungen',
                title: 'Bestellung %{reference}',
                fields: {
                    basket: {
                        delivery: 'Lieferung',
                        reference: 'Reference',
                        quantity: 'Menge',
                        sum: 'Summe',
                        tax_rate: 'Steuern',
                        total: 'Gesamt',
                        unit_price: 'Preis',
                    },
                    address: 'Adresse',
                    customer_id: 'Kunde',
                    date_gte: 'bis',
                    date_lte: 'von',
                    nb_items: 'Nb articles',
                    reference: 'Référence',
                    returned: 'Returned',
                    status: 'Status',
                    total_gte: 'Min amount',
                },
            },
            invoices: {
                name: 'Rechnung |||| Rechnungen',
                fields: {
                    date: 'Rechnungsdatum',
                    customer_id: 'Kunde',
                    command_id: 'Bestellung',
                    date_gte: 'von',
                    date_lte: 'bis',
                    total_gte: 'Min amount',
                    address: 'Adresse',
                },
            },
            products: {
                name: 'Angebot |||| Angebote',
                fields: {
                    id: 'Identifier',
                    category_id: 'Kategorie',
                    height_gte: 'min. Höhe',
                    height_lte: 'max. Höhe',
                    height: 'Höhe',
                    image: 'Bild',
                    price: 'Preis',
                    reference: 'Reference',
                    sales: 'Verkäufe',
                    stock_lte: 'Low Stock',
                    stock: 'Stock',
                    thumbnail: 'Thumbnail',
                    width_gte: 'min. Breite',
                    width_lte: 'max. Breite',
                    width: 'Breite',
                },
                tabs: {
                    image: 'Bild',
                    details: 'Details',
                    description: 'Beschreibung',
                    reviews: 'Bewertungen',
                },
                filters: {
                    categories: 'Kategorien',
                    stock: 'Stock',
                    no_stock: 'Out of stock',
                    low_stock: '1 - 9 Stk.',
                    average_stock: '10 - 49 Stk.',
                    enough_stock: '50 Stk. & mehr',
                    sales: 'Verkäufe',
                    best_sellers: 'Best sellers',
                    average_sellers: 'Average',
                    low_sellers: 'Low',
                    never_sold: 'Never sold',
                },
            },
            categories: {
                name: 'Kategorie |||| Kategorien',
                fields: {
                    products: 'Produkte',
                },
                actions: {
                    create_product: 'Create Poster',
                },
            },
            reviews: {
                name: 'Bewertung |||| Bewertungen',
                amount: 'Eine Bewertung |||| %{smart_count} Bewertungen',
                relative_to_poster: 'Review on poster',
                detail: 'Details',
                fields: {
                    customer_id: 'Kunden',
                    command_id: 'Aufträge',
                    product_id: 'Produkt',
                    date_gte: 'Posted since',
                    date_lte: 'Posted before',
                    date: 'Datum',
                    comment: 'Kommentar',
                    rating: 'Rating',
                },
                action: {
                    accept: 'Akzeptieren',
                    reject: 'Zurückweisen',
                },
                notification: {
                    approved_success: 'Bewertung akzeptiert',
                    approved_error: 'Fehler beim akzeptieren der Bewertung',
                    rejected_success: 'Bewertung zurückgewiesen',
                    rejected_error: 'Fehler beim zurückweisen der Bewertung',
                },
            },
            segments: {
                name: 'Segments',
                fields: {
                    customers: 'Kunden',
                    name: 'Name',
                },
                data: {
                    compulsive: 'Compulsive',
                    collector: 'Collector',
                    ordered_once: 'Ordered once',
                    regular: 'Regular',
                    returns: 'Returns',
                    reviewer: 'Reviewer',
                },
            },
            stores: {
                name: 'Läden',
                fields: {
                    city: 'Stadt',
                    country: 'Land',
                    address: 'Adresse',
                    created_at: 'Created at',
                },
            },
            tours: {
                name: 'Lernen',
            },
            locks: {
                overlay: 'Currently Edited by %{name}',
            },
            events: {
                name: 'Events',
            },
        },
        tours: {
            action: {
                play: 'Abspielen',
            },
            message: {
                played_on: 'Zuletzt abgespielt am %{date}',
                never_played: 'noch nie abgespielt',
            },
            'ra-preferences': {
                comment:
                    'Persist user preferences (language, ui, filters, displayed columns, etc) in local storage',
                intro: 'ra-preferences comes with a lot of built-in modules, like this theme switcher. Try it: it works!',
                language_switcher: 'Or this language switcher...',
                persisted_queries:
                    "It features persisted queries too. For example, let's persist the filters for today visitors who actually ordered something.",
                persisted_queries_result:
                    "It's persisted locally in the browser now!",
                list_customization:
                    'It even has more advanced components like this list customization tool.',
                list_customization_columns:
                    'Where you can select how you want the list to be displayed, or the information you want to see.',
                hook: 'It exposes simple hooks so that you can actually save whatever you want, too. For instance, the state of this particular step. Try to reload the page!',
            },
            'ra-search': {
                title: 'Suche',
                comment:
                    'Auf Wunsch schließen wir Ihre Suchmaschine an und lassen Benutzer über ein intelligentes Omnisearch-Feld über alle Ressourcen suchen',
                intro: 'Dies ist das intelligente Omnisearch-Feld. Es ermöglicht Benutzern, über alle Ressourcen hinweg zu suchen.',
                customize:
                    'Wir können die Suchergebnisse nach Belieben anpassen und zu einer beliebigen Ressource umleiten. Klicken Sie zum Beispiel auf den ersten Kunden.',
                preserved_across_navigation:
                    'Die Suchanfrage und die Ergebnisse bleiben nach der Navigation erhalten.',
            },
            'ra-navigation-breadcrumb': {
                comment:
                    'Keep a track of your location in the site and navigate easily.',
                title: 'Navigation',
                intro: 'The breadcrumb indicates that we are on the posters page.',
                edit: "Let's edit one of these posters.",
                sync: 'The breadcrumb has changed to follow us to this Edit page.',
                navigate: "Let's try to navigate away using the Menu entry.",
                sync2: 'The breadcrumb keeps showing our exact location.',
                clickable:
                    "Users can click on the breadcrumb items directly to navigate.\nLet's go to the home page.",
                dashboard:
                    "By default, there is no breacrumb displayed on the home page.\n Now it's your turn to use the Breadcrumb component to build you own breadcrumb path!",
            },
            'ra-realtime': {
                comment:
                    'Enable realtime on menu, list, show and edit views - or anywhere you want.',
                intro: "Seems like you just had new orders, let's check...",
                title: 'Echtzeit',
                new_orders: 'Your new orders can stand-out from others.',
                newest: "And newest orders even appear while you're on the page.",
                locks: 'You can lock resources in realtime (this one will be unlocked in a few seconds).',
                end: "That's it for ra-realtime. Have a look at the other tours to discover more enterprise features!",
            },
            'ra-editable-datagrid': {
                comment:
                    'Quickly edit your data without leaving your datagrid.',
                title: 'direktes Editieren',
                intro: "Hovering on a row shows a toolbar allowing to either edit or delete the record. Let's see what happens when editing a row by clicking on the Edit button (or by directly clicking inside the row).",
                edit: "You can edit a record without leaving the Datagrid! Let's change the address.",
                save: 'After edition, just click on the Save button in the row.',
                create: 'The Editable Datagrid also supports inline creation.',
                forms: 'A row edition / creation form can contain Inputs of any type (text, date, number, etc.).',
                create_save:
                    'Click on the Save button to submit the form and create a new record.',
            },
            'ra-tree': {
                title: 'Baumnavigation',
                comment:
                    'Edit and visualize tree structures. Reorganize by drag and drop. Adapts to any data structure on the backend (parent_id, children, nested sets, etc)...',
                intro: 'ra-tree helps handling trees with ease, no matter the data structure you use on the backend.',
                infinite_levels:
                    'It supports expanding or collapsing nodes for an infinite amount of levels.',
                changes:
                    'You can even add a new category, or reorder them, try it!',
            },
            'ra-markdown': {
                title: 'formatierte Texte',
                comment:
                    'Read Markdown data, and edit it using a WYSIWYG editor in your admin.',
                intro: "This is a poster, one of the products our shop is selling, let's go to its details.",
                editor_location:
                    'The markdown editor is in the description tab.',
                editor: 'Right here. Try playing with its markdown, make it bold, add headlines!',
                wysiwyg: 'By default, you are in WYSIWYG mode.',
                raw: 'But you can switch to raw markdown edition with this button.',
                show: 'Parsed markdown can then be displayed in a preview or wherever you want.',
            },
        },
    }
);

export default customGermanMessages;
